<script setup lang="ts">
import type { SharedModal } from '~/components/shared/SharedModal.vue';
import { computed } from 'vue';

const props = defineProps<{
    modal: SharedModal;
    id: string;
}>();

const modalContent = computed(() => props.modal.modalContent?.value);
const modalProps = computed(() => props.modal.modalProps?.value);
const modalId = computed(() => props.modal.modalId?.value);
</script>

<template>
    <ClientOnly>
        <Teleport
            to="#modal-content"
            :disabled="id !== modalId"
        >
            <component
                :is="modalContent"
                v-if="modalContent && id === modalId"
                v-bind="modalProps"
                @close="modal.close"
            />
        </Teleport>
    </ClientOnly>
</template>
